import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Alert, Box, Snackbar } from '@mui/material';
import ChooseTypeAllocation from '../uploadTask/chooseTypeAllocation';
import AllocationStepHeading from './allocationStepListHeading';
import { AddButton } from '../button/button';
import { useState } from 'react';
import StepFirst from './allocationType3/type3AllocationStepHeader';
import Type3Preview from './allocationType3/type3AllocationPreview';
import Type1Allocation from './allocationType1/type1AllocationStepHeader';
import Type1AllocationEmail from './allocationType1/type1AllocationEmailSelection';
import { createBulkAllocation, createBulkUnallocation, setCreateBulkAllocationStatus, setCreateBulkUnallocationStatus } from '../../redux/slices/upload';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useNavigate, useParams } from 'react-router-dom';
import DataInformationAllocationTable from './dataTableInformation';
import { clearIds, SelectedId } from '../../redux/reducers/taskIdSlice';
import Type2AllocationSteps from './allocationType2/type2AllocationStep';
import { createBulkAnalyse, setCreateAnalyseStatus } from '../../redux/slices/bulkAllocation';
import UploadSheetsPreview from './allocationType2/uploadSheetForEmailPreview';
import UploadShetForEmails from './allocationType2/uploadSheetForEmails';
import CopyPasteEmails from './allocationType2/copyPasteEmails';
import { STATUS } from '../../utils/status';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { clearEmails } from '../../redux/reducers/selectedEmail';
import { clearEmailss } from '../../redux/reducers/selectedEmailReviewer';
import { clearEmailTasks } from '../../redux/reducers/emailTaskSlice';
import DeleteModal from '../modal/deleteModal';
import { clearFile } from '../../redux/reducers/sheetAllocationType3';
import { clearFileType2 } from '../../redux/reducers/uploadSheetType2';

interface SheetData {
  name: string;
  data: any[][];
}

export default function CustomizedSteppers() {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [sheets, setSheets] = useState<SheetData[]>([]);
  const [uploadSheets, setUploadSheets] = useState<SheetData[]>([]);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const selectedEmails = useSelector((state: RootState) => state.selectedEmails.selectedEmail);
 
  const selectedEmailsReview = useSelector((state: RootState) => state.selectedEmailsReview.selectedEmailReview);
  const selectedIds = useSelector((state: RootState) => state.tasks.selectedIds);
  console.log({selectedIds})
  const { option } = useParams();
  const words = option && option.split(' ');
  const taskerType = words && words[0].toUpperCase();
  const allocationtype = words && words[1].toLowerCase();
  const fileDataType2 = useSelector((state: RootState) => state.uploadSheet.fileUpload);
  const fileType3 = useSelector((state: RootState) => state.fileAllocation.file)
  const [selectedBox, setSelectedBox] = useState<string | null>(null);
  const emailTasksType2 = useSelector((state: RootState) => state.emailTasks.emailTasks);
  const createBulkAllocationStatus = useSelector((state: RootState) => state.upload.createBulkAllocationStatus);
  const createBulkUnallocationStatus = useSelector((state: RootState) => state.upload.createBulkUnallocationStatus);
  const createAnalyseStatus = useSelector((state: RootState) => state.bulkAllocation.createStatus);

  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [errorCopyPaste, setErrorCopyPaste] = useState<string | null>(null);
  const [hasErrors, setHasErrors] = useState(false);
  const [isPastingCopyPaste, setIsPastingCopyPaste] = useState<boolean>(true);

  const handleError = (errorMessage: string | null) => {
    setErrorCopyPaste(errorMessage);
  };

  const handleRowErrorsChange = (errors: boolean) => {
    setHasErrors(errors);
  };

  const handleCloseSubmitModal = () => {
    setOpenSubmitModal(false);
  }

  const handleOpenSubmitModal = () => {
    setOpenSubmitModal(true);
  }

  React.useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.returnValue = '';
    };
    
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  React.useEffect(() => {
    window.history.pushState(null, '', window.location.href);

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    const handleBeforeUnloadDialog = (event: PopStateEvent) => {
      event.preventDefault();
      window.history.pushState(null, '', window.location.href);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handleBeforeUnloadDialog);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handleBeforeUnloadDialog);
    };
  }, []);

  const handleTypeClick = (type: string) => {
    setSelectedType(type);
  };

  const handleDoubleClick = (step: number) => {
    setActiveStep(step);
  };

  const steps = 
  (allocationtype === 'unallocation' && selectedType === 'Type 1') 
    ? ['Choose Type', 'Select & Submit'] 
    : (selectedType === 'Type 2' && selectedBox === 'copyPaste') 
      ? ['Choose Type', 'Select or Upload', 'Verify & Submit'] 
      : ['Choose Type', 'Select or Upload', 'Preview', 'Verify & Submit'];

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <ChooseTypeAllocation
            selectedType={selectedType}
            onTypeClick={handleTypeClick}
            activeStep={activeStep}
            onDoubleClick={handleDoubleClick}
            allocationType={allocationtype || ''}
          />
        );

      case 1:
        if (selectedType === 'Type 1') {
          return <Type1Allocation allocationType={taskerType || 'TASK'} />;
        } else if (selectedType === 'Type 2') {
          return (
            <>
              <Type2AllocationSteps selectedBox={selectedBox} setSelectedBox={setSelectedBox} />
              {selectedBox === 'uploadSheet' && <UploadShetForEmails setUploadSheets={setUploadSheets} selectedBox={selectedBox} setSelectedBox={setSelectedBox} />}
              {selectedBox === 'copyPaste' && <CopyPasteEmails activeStep={activeStep} onError={(errorMessage) => handleError(errorMessage)} setIsPastingParent={setIsPastingCopyPaste} selectedBox={selectedBox} setSelectedBox={setSelectedBox} onRowErrorsChange={handleRowErrorsChange}/>}
            </>
          )

        } else if (selectedType === 'Type 3') {
          return <StepFirst setSheets={setSheets} />;
        }
        return null;

      case 2:
        if (selectedType === 'Type 1' && allocationtype === 'allocation') {
          return <Type1AllocationEmail allocationType={taskerType || 'TASK'} />
        } 
        else if(selectedType === 'Type 1' && allocationtype === 'unallocation'){
          return <DataInformationAllocationTable allocationType={allocationtype}/>;
        }
        else if (selectedType === 'Type 2') {
          return (
            <>
              {selectedBox === 'uploadSheet' && fileDataType2!==null && <UploadSheetsPreview uploadSheets={uploadSheets} />}
              {selectedBox === 'copyPaste' && <DataInformationAllocationTable allocationType={allocationtype || ''}/>}
            </>
          )
        }
        else if (selectedType === 'Type 3') {
          return <Type3Preview sheets={sheets} />;
        }
        return null;

      case 3:
        return <DataInformationAllocationTable allocationType={allocationtype || ''}/>;

      // case 4:
      //   return <Type3AllocationSubmit />;

      default:
        return null;
    }
  };

  const handleNext = () => {

    if (activeStep !== 2) {
      setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
    }
    if(activeStep === 1){
      if (selectedBox === 'copyPaste' && selectedType === 'Type 2') {
        const payload = {
          project_id: id,
          type: taskerType,
          action: allocationtype === 'allocation'? 'task_distribution':'bulk_task',
          action_type: allocationtype,
          data: emailTasksType2.map(task => ({ email: task.email, count: task.count }))
        }
        dispatch(createBulkAnalyse({ payload }))
      }
    }
    if (activeStep === 2) {
      if (selectedType === 'Type 1') {
        const payload = {
          project_id: id,
          data: {
            tasks: selectedIds.map((selectedId: SelectedId) => selectedId.id),
            experts: taskerType === 'TASK' ? selectedEmails : selectedEmailsReview,
          },
          action_type: allocationtype,
          type: taskerType,
          action: allocationtype === 'allocation'? 'task_expert_mapping':'task_expert_unmapping',
        }
        if(allocationtype === 'allocation'){
          dispatch(createBulkAnalyse({ payload }))
        }  
      }

      else if (selectedType === 'Type 2') {
          const formData = new FormData()
          formData.append("project_id", id || '')
          formData.append("action", allocationtype === 'allocation'? 'task_distribution': 'bulk_task')
          formData.append("type", taskerType || 'TASK')
          formData.append("action_type", allocationtype || '')
          if (fileDataType2) {
            formData.append("data", fileDataType2);
          }
          dispatch(createBulkAnalyse({ payload: formData }))
        
      }

      else if (selectedType === 'Type 3') {
        const formData = new FormData()
        formData.append("project_id", id || '')
        formData.append("action", allocationtype === 'allocation'? 'task_assignment':'task_unassignment')
        formData.append("type", taskerType || 'TASK')
        formData.append("action_type", allocationtype || '')
        if (fileType3) {
          formData.append("data", fileType3);
        }
        dispatch(createBulkAnalyse({ payload: formData }))
      }
    }
  };

  const handleAllocate = () => {
    if (selectedType === 'Type 1') {
      const payload = {
        project_id: id,
        data: {
          tasks: selectedIds.map((selectedId: SelectedId) => selectedId.id),
          experts: taskerType === 'TASK' ? selectedEmails : selectedEmailsReview,
        },
        type: taskerType,
        action: 'task_expert_mapping',
      }
      dispatch(createBulkAllocation({ payload }))
    }

    else if (selectedType === 'Type 2') {
      if (selectedBox === 'copyPaste') {
        const payload = {
          project_id: id,
          type: taskerType,
          action: 'task_distribution',
          data: emailTasksType2.map(task => ({ email: task.email, count: task.count }))
        }
        dispatch(createBulkAllocation({ payload }));
      }
      else {
        const formData = new FormData()
        formData.append("project_id", id || '')
        formData.append("action", 'task_distribution')
        formData.append("type", taskerType || 'TASK')
        if (fileDataType2) {
          formData.append("data", fileDataType2);
        }
        dispatch(createBulkAllocation({ payload: formData }))
      }

    }

    else if (selectedType === 'Type 3') {
      const formData = new FormData()
      formData.append("project_id", id || '')
      formData.append("action", 'task_assignment')
      formData.append("type", taskerType || 'TASK')
      if (fileType3) {
        formData.append("data", fileType3);
      }
      dispatch(createBulkAllocation({ payload: formData }))
    }
  };

  const handleUnallocate = () => {
    if (selectedType === 'Type 1') {
      const payload = {
        project_id: id,
        data: {
          tasks: selectedIds.map((selectedId: SelectedId) => selectedId.id),
        },
        type: taskerType,
        action: 'task_expert_unmapping',
      }
      dispatch(createBulkUnallocation({ payload }))
    }

    else if (selectedType === 'Type 2') {
      if (selectedBox === 'copyPaste') {
        const payload = {
          project_id: id,
          type: taskerType,
          action: 'bulk_task',
          data: emailTasksType2.map(task => ({ email: task.email, count: parseInt(task.count) }))
        }
        dispatch(createBulkUnallocation({ payload }));
      }
      else {
        const formData = new FormData()
        formData.append("project_id", id || '')
        formData.append("action", 'bulk_task')
        formData.append("type", taskerType || 'TASK')
        if (fileDataType2) {
          formData.append("data", fileDataType2);
        }
        dispatch(createBulkUnallocation({ payload: formData }))
      }
    }

    else if (selectedType === 'Type 3') {
      const formData = new FormData()
      formData.append("project_id", id || '')
      formData.append("action", 'task_unassignment')
      formData.append("type", taskerType || 'TASK')
      if (fileType3) {
        formData.append("data", fileType3);
      }
      dispatch(createBulkUnallocation({ payload: formData }))
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  React.useEffect(() => {
      if (createBulkAllocationStatus === STATUS.SUCESS) {
        if (taskerType && taskerType === 'TASK') {
          dispatch(clearEmails());
        } else {
          dispatch(clearEmailss());
        }
        handleSnackbar('Allocated Successfully', 'success');
        dispatch(clearEmailTasks())
        dispatch(clearFile())
        dispatch(clearFileType2())
        dispatch(clearIds())
        dispatch(clearEmails());
        setTimeout(() => {
          navigate(`/project/${id}?tab=task`);
        }, 1000);
      }

      if (createBulkAllocationStatus === STATUS.FAILED) {
        handleSnackbar('Error Occurred, Please try again!', 'error');
      }
 
    return () => {
      if(createBulkAllocationStatus === STATUS.SUCESS){
      dispatch(setCreateBulkAllocationStatus(STATUS.NOT_STARTED));
      }
    };
  }, [createBulkAllocationStatus, navigate, dispatch]);

  React.useEffect(()=>{
        dispatch(clearEmailTasks())
        dispatch(clearFile())
        dispatch(clearFileType2())
        dispatch(clearIds())
        dispatch(clearEmails())
        dispatch(clearEmailss());
  },[allocationtype])

  React.useEffect(() => {

    if (createBulkUnallocationStatus === STATUS.SUCESS) {
      handleSnackbar('Unllocated Successfully', 'success');
      dispatch(clearEmailTasks())
      dispatch(clearFile())
      dispatch(clearFileType2())
      dispatch(clearIds())
      dispatch(clearEmails());
      setTimeout(() => {
        navigate(`/project/${id}?tab=task`);
      }, 1000);
    }

    if (createBulkUnallocationStatus === STATUS.FAILED) {
      handleSnackbar('Error Occurred, Please try again!', 'error');
    }

  return () => {
    if(createBulkUnallocationStatus === STATUS.SUCESS){
    dispatch(setCreateBulkUnallocationStatus(STATUS.NOT_STARTED));
    }
  };
}, [createBulkUnallocationStatus, navigate, dispatch]);

  React.useEffect(() => {
      if (createAnalyseStatus === STATUS.SUCESS) {
        if (activeStep === 2) {
          setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
        }
      }

      if (createAnalyseStatus === STATUS.FAILED) {
        handleSnackbar('Wrong Format, Please download template to analyze', 'error');
      }
    return () => {
      if (createAnalyseStatus === STATUS.SUCESS) {
         dispatch(setCreateAnalyseStatus(STATUS.NOT_STARTED));
      }
     };
  }, [createAnalyseStatus, navigate, dispatch]);

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  React.useEffect(()=>{
    if(taskerType && taskerType === 'TASK'){
      dispatch(clearEmails());
    } 
    else{
      dispatch(clearEmailss());
    }
  },[selectedIds])

  const isNextDisabled = () => {
    if (activeStep === 0 && !selectedType) {
      return true;
    }
    if (activeStep === 1 && selectedIds.length === 0 && selectedType === 'Type 1') {
      return true;
    }
    if (activeStep === 1 && !selectedBox && selectedType === 'Type 2') {
      return true;
    }
    if (activeStep === 1 && fileType3 === null && selectedType === 'Type 3') {
      return true;
    }
    if (activeStep === 2 && taskerType === 'TASK' && selectedEmails.length === 0 && selectedType === 'Type 1') {
      return true;
    }
    if (activeStep === 2 && taskerType === 'REVIEW' && selectedEmailsReview.length === 0 && selectedType === 'Type 1') {
      return true;
    }
    if (activeStep === 1 && hasErrors && selectedBox === 'copyPaste' && selectedType === 'Type 2') {
      return true;
    }
    if (activeStep === 1 && emailTasksType2.length === 0 && selectedBox === 'copyPaste' && selectedType === 'Type 2') {
      return true;
    }
    if (activeStep === 1 && isPastingCopyPaste && selectedBox === 'copyPaste' && selectedType === 'Type 2') {
      return true;
    }
    if (activeStep === 1 && fileDataType2 === null && selectedBox === 'uploadSheet' && selectedType === 'Type 2') {
      return true;
    }
    return false;
  };

  const isNextDisabledUnallocation = () => {
    if (activeStep === 0 && !selectedType) {
      return true;
    }
    if (activeStep === 1 && selectedIds.length === 0 && selectedType === 'Type 1') {
      return true;
    }
    return false;
  };

  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <section className="fixed top-0 inset-x-0 h-[10px] z-40 drop-shadow bg-[#F7F9FA]" style={{ marginLeft: '7%' }}>
        <AllocationStepHeading allocationType={selectedType} />
      </section>
      <section className="fixed top-0 inset-x-0 h-[88px] z-40 drop-shadow mt-44 ml-52 mb-56">
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </section>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '4rem' }}>
        <section
          style={{
            ...( (selectedType !== 'Type 1') ? {height: 'calc(100vh - 400px)'} : {}),
            ...((selectedType === 'Type 1' && activeStep === 2)? {height: 'calc(100vh - 400px)'} : {}),
            overflowY: 'auto',
            padding: '12px, 0px, 12px, 0px',
            marginBottom: '15rem',
            marginTop: '14rem',
            flexGrow: 1
          }}
        >
          {renderStepContent(activeStep)}
        </section>
      </Box>
      <section className="fixed bottom-0 inset-x-0 h-[58px] bg-white z-40 drop-shadow">
        <Box
          sx={{
            mt: '0.1rem',
            display: 'flex',
            justifyContent: 'flex-end',
            mr:'0.4rem'
          }}
        >
          {activeStep !== 0 && (
            <AddButton variant="contained" onClick={handleBack}>
              Previous
            </AddButton>
          )}

          <AddButton
            variant="contained"
            onClick={activeStep === steps.length - 1 ? handleOpenSubmitModal : handleNext}
            disabled={allocationtype === 'allocation' ? isNextDisabled(): isNextDisabledUnallocation()}
          >
            {activeStep === steps.length - 1 ? allocationtype === 'allocation'? 'Allocate':'Unallocate' : 'Next'}
          </AddButton>

        </Box>
      </section>
      {errorCopyPaste && (
        <Snackbar open={true} autoHideDuration={6000} onClose={() => setErrorCopyPaste(null)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={() => setErrorCopyPaste(null)} severity="error">
            {errorCopyPaste}
          </Alert>
        </Snackbar>
      )}
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
      <DeleteModal 
      open={openSubmitModal} 
      onCancel={handleCloseSubmitModal} 
      onConfirm={allocationtype === 'allocation'? handleAllocate : handleUnallocate}
      deleteText={allocationtype === 'allocation'? 'Allocate':'Unallocate'}
      isButtonLoading={allocationtype === 'allocation'? createBulkAllocationStatus === STATUS.PENDING: createBulkUnallocationStatus === STATUS.PENDING}
      />
    </div>
  );
}
