import React, { useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Checkbox, Popover, ListItem, List, ListItemText, Drawer, SelectChangeEvent, Typography } from '@mui/material';
import { TableCellHead } from '../../table/tableCell';
import { STATUS } from '../../../utils/status';
import Type1AllocationTableRow from './type1AllocationTableRow';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store/appStore';
import { useStyles } from '../../../assets/styles/listTableStyle';
import Error from '../../error/error';
import { getUpload } from '../../../redux/slices/upload';
import { useParams } from 'react-router-dom';
import TaskCompleted from '../../taskCompleted/taskCompleted';
import Pagination from '../../uploadTask/taskPagination';
import PaginationEntries from '../../pagination/paginationEntries';
import { clearIds, setIds } from '../../../redux/reducers/taskIdSlice';
import { SearchBar } from '../../inputField/textfield';

interface Type1AllocationProps {
  allocationType: string | null;
}

const Type1Allocation: React.FC<Type1AllocationProps> = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [searchTerm, setSearchTerm] = useState('');
  const [loading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const { id } = useParams();
  const { option } = useParams();
  const words = option && option.split(' ');
  const taskerType = words && words[0];
  const allocationtype = words && words[1].toLowerCase();
  const [type] = useState(taskerType && taskerType === 'Task'
    ? (allocationtype === 'unallocation' ? 'get_completed_tasks, get_not_started_tasks, task_allocation' : 'get_unallocated_tasks')
    : (taskerType === 'Review' ? (allocationtype === 'unallocation' ? 'get_completed_reviews, get_not_started_reviews, review_allocation' : 'get_allocated_reviews') : ''));
  const getStatus = useSelector((state: RootState) => state.upload.getStatus);
  const upload = useSelector((state: RootState) => state.upload.upload);
  const totalPages = Math.ceil((upload.count || 0) / itemsPerPage);
  const selectedIds = useSelector((state: RootState) => state.tasks.selectedIds);
  const currentPageTasks = upload.results || [];
  const currentSelectedIds = selectedIds.filter(selectedId =>
    currentPageTasks.some(task => task.id === selectedId.id)
  );

  console.log(taskerType)


  const headers = [
    "TASK NAME",
    `${taskerType === "Review" ? "REVIEW" : "TASK"} STATUS`,
    `${taskerType === "Review" ? "REVIEWER" : "TASKER"} PSEUDO NAME`,
    `${taskerType === "Review" ? "REVIEW" : "TASKER"} ALLOCATION`
  ];
  // const allSelected = (upload.results || []).length > 0 && (upload.results || []).every(row => selectedIds.some(id => id.id === row.id));
  const allSelected = currentPageTasks.length > 0 && currentPageTasks.every(row => selectedIds.some(id => id.id === row.id));

  console.log(type)
  useEffect(() => {
    dispatch(getUpload({ search: searchTerm, project_id: id || '', page: currentPage, type: type, pageSize: itemsPerPage }));
  }, [dispatch, searchTerm, id, currentPage, itemsPerPage, type]);

  const handleSelectAllClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const allIds = (upload.results || []).map(row => ({ id: row.id, task_name: row.task_name }));

  const handleDoneClick = () => {
    const isAllSelectedOnPage = currentPageTasks.every(task =>
      selectedIds.some(selectedId => selectedId.id === task.id)
    );

    if (isAllSelectedOnPage) {
      const newSelectedIds = selectedIds.filter(
        (selectedId) => !currentPageTasks.some(task => task.id === selectedId.id)
      );
      dispatch(setIds(newSelectedIds));
    } else {
      const newSelectedIds = [
        ...selectedIds,
        ...currentPageTasks.filter(task =>
          !selectedIds.some(selectedId => selectedId.id === task.id)
        ).map(task => ({ id: task.id, task_name: task.task_name }))
      ];
      dispatch(setIds(newSelectedIds));
    }

    handleClosePopover();
  };


  const handleChangeRowsPerPage = (value: number) => {
    console.log(value)
    setItemsPerPage(value as number);
    setCurrentPage(1);
    dispatch(getUpload({ search: searchTerm, page: 1, pageSize: value as number, project_id: id || "", type: type }));
  };
  // const handleOnChange = (value:number) => {
  //   setItemsPerPage(value)
  // }

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1)
    setSearchTerm(event.target.value);

  };
  
  return (
    <>
      <div style={{ display: 'flex', height: '10vh', justifyContent: 'space-between', marginBottom: '20px' }} >
        <div style={{ display: 'flex' }}>
          <Typography style={{ fontFamily: 'Montserrat', fontSize: '20px', fontWeight: '600', marginTop: '20px' }}>{allocationtype === 'allocation' ? 'Unallocated Tasks' : 'Allocated Tasks'}</Typography>
          <Typography style={{ fontFamily: 'Montserrat', fontSize: '20px', fontWeight: '400', marginTop: '20px', marginLeft: '10px', color: 'grey' }}>
            ({getStatus === STATUS.PENDING ? <CircularProgress sx={{ fontSize: '12px' }} /> : `${selectedIds.length > 0 && getStatus === STATUS.SUCESS ? `${selectedIds.length}/${upload.count}` : `${upload.count}`}`})
          </Typography>
          {allocationtype === 'unallocation' &&
            <div style={{ marginTop: '18px', marginLeft: '20px' }}>
              <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
            </div>
          }
        </div>
        <PaginationEntries itemsPerPage={itemsPerPage} onChangeRowsPerPage={handleChangeRowsPerPage} />
      </div>

      <div style={{ overflowY: 'scroll', height: '30vh' }} >
        <TableContainer>
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          )}
          {!loading && (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ overflow: 'hidden', backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }} >
                <TableRow>
                  <TableCellHead>
                    <Checkbox
                      checked={allSelected}
                      onClick={handleSelectAllClick}
                    />
                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleClosePopover}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <div>
                        <List>
                          <ListItem
                            selected={allIds.length === selectedIds.length}
                            onClick={() => handleDoneClick()}
                            sx={{ cursor: 'pointer' }}
                          >
                            <ListItemText
                              primary={`${currentSelectedIds.length === currentPageTasks.length ? 'Deselect' : 'Select'} ${currentPageTasks.length} tasks`}
                            />
                          </ListItem>
                        </List>
                      </div>
                    </Popover>
                  </TableCellHead>
                  {headers.map((header) => (
                    <TableCellHead key={header}>{header}</TableCellHead>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {getStatus === STATUS.SUCESS && upload && upload.results && upload.results.length > 0 ? (
                  upload.results.map((row: any) => (
                    <>
                      <Type1AllocationTableRow
                        key={row.id}
                        row={row}

                      />
                    </>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={headers.length} className={classes.noUsersMessage}>
                      <Error message={'There are no tasks added as of yet. You can add a task to see it here.'} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={paginate}
        itemsPerPage={itemsPerPage}
      />
    </>
  )
}

export default Type1Allocation
