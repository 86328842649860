import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, SelectChangeEvent, Checkbox, Popover, List, ListItem, ListItemText, Button, Divider, MenuItem, Menu, Drawer, FormControl, InputLabel, Select, Typography, IconButton, Box, Tabs, Tab, Chip, TextField, Tooltip, avatarClasses, Badge, FormGroup, FormControlLabel, FormLabel, RadioGroup, Radio, ListSubheader, ListItemButton, ListItemIcon, Collapse, Autocomplete, selectClasses, DialogActions, Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { useNavigate, useParams } from 'react-router-dom';
import { AddButton } from '../button/button';
import { TableCellHead } from '../table/tableCell';
import PaginationEntries from '../pagination/paginationEntries';
import { SearchBar } from '../inputField/textfield';
import { useStyles } from '../../assets/styles/listTableStyle';
import TableRowItem from './taskUploadRowItem';
import DeleteModal from '../modal/deleteModal';
import { getUpload, updateUpload } from '../../redux/slices/upload';
import Error from '../error/error';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Pagination from './taskPagination';
import AllocationModal from './allocateModal';
import InfoModal from './infoModal';
import { getTaskers } from '../../redux/slices/expertSlice/addExpertSlice';
import { setIds } from '../../redux/reducers/taskIdSlice';
import ConfirmationDialog from './confirmationModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { clearEmails } from '../../redux/reducers/selectedEmail';
import { createAllocation, setCreateAllocationStatus } from '../../redux/slices/allocation';
import SnackbarNotification from '../snackbar/snackbarNotification';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoModalReview from './infoModalReview';
import { clearEmailss } from '../../redux/reducers/selectedEmailReviewer';
import TaskCompletedMain from '../taskCompleted/taskCompletedMain';
import TaskCompleted from '../taskCompleted/taskCompleted';
// import TaskCompleted, { getCompletedTask } from '../../redux/slices/taskCompleted';
// import TaskCompleted from '../uploadTask/'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import BlockIcon from '@mui/icons-material/Block';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import debounce from 'lodash.debounce';
import { getVendorAction } from '../../redux/slices/vendor';
import { taskFilterTypesMenuItems, taskSearchTypesMenuItems } from './data';
import { getTaskData, setGetTaskDataStatus } from '../../redux/slices/taskData';
import InfoModelTaskUnallocation from './infoModelTaskUnallocation';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';

const getUniqueTaskers = (taskers: any[]) => {
  const uniqueTaskers: any[] = [];
  const seenEmails = new Set<string>();

  taskers && taskers.forEach(tasker => {
    if (!seenEmails.has(tasker.email)) {
      uniqueTaskers.push(tasker);
      seenEmails.add(tasker.email);
    }
  });

  return uniqueTaskers;
};

interface Filters {
  taskName: string;
  taskAllocation: string;
  reviewAllocation: string;
  pseudoname: string;
  // selectedTaskType: any;
  // selectedStatus: any;
  // selectedVendor: any;

}
interface sideMenuProps {
  open: any,
  onClose: any,
  setPseudoName: any
}




const TaskUploadTable = () => {
  const updateStatus = useSelector((state: RootState) => state.upload.updateStatus);
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openInfoModalReview, setOpenInfoModalReview] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const getStatus = useSelector((state: RootState) => state.upload.getStatus);
  const { getTaskDataStatus, taskData } = useSelector((state: RootState) => state.taskData);
  const selectedEmails = useSelector((state: RootState) => state.selectedEmails);
  const selectedEmailsReview = useSelector((state: RootState) => state.selectedEmailsReview);
  const deadline = useSelector((state: RootState) => state.deadline.deadline)
  const selectedIds = useSelector((state: RootState) => state.tasks.selectedIds);
  const upload = useSelector((state: RootState) => state.upload.upload);
  const [filteredTaskers, setFilteredTaskers] = useState<any[]>([]);
  const [filteredReviewers, setFilteredReviewers] = useState<any[]>([]);
  const [allocationType, setAllocationType] = useState('');
  const selectedTasksWithoutAllocation = useSelector((state: RootState) => state.allocationTask.tasksWithoutAllocation);
  const selectedTasksWithAllocation = useSelector((state: RootState) => state.allocationTask.tasksWithAllocation);
  const selectedReviewsWithoutAllocation = useSelector((state: RootState) => state.allocationTask.reviewWithoutAllocation);
  const fetchPermissions = useSelector((state: RootState) => state.projects.permissions);
  const association_type = useSelector((state: RootState) => state.projects.association_type);
  const association_type_company = association_type && association_type === 'COMPANY' ? true : false;
  const association_type_vendor = association_type && association_type === 'VENDOR' ? true : false;
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);

  const { taskers: tasker_wrapper } = useSelector((state: RootState) => state.projects.projects as any);
  const { reviewers: reviewer_wrapper, project: projectDetails } = useSelector((state: RootState) => state.projects as any);

  let taskers = tasker_wrapper && tasker_wrapper.map((tasker: any) => tasker.expert)
  taskers = getUniqueTaskers(taskers)

  let reviewers = reviewer_wrapper === undefined ? [] : reviewer_wrapper.map((reviewer: any) => reviewer.expert)
  reviewers = getUniqueTaskers(reviewers)

  const Vendors = useSelector((state: RootState) => state.vendors.vendors)
  const getVendorStatus = useSelector((state: RootState) => state.vendors.getVendorsStatus)

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const createStatus = useSelector((state: RootState) => state.allocation.createStatus);
  let [type, setType] = useState("")
  const [drawer, setDrawer] = useState(false);
  const [currentTaskID, setcurrentTaskID] = useState<string>('')
  const [openFilterList, setOpenFilterList] = useState(false)
  const [anchorE2, setAnchorE2] = useState(null);
  const [anchorE3, setAnchorE3] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [value, setValue] = useState('1')
  const [searchType, setSearchType] = useState("")

  const [taskName, setTaskName] = useState("")
  const [taskAllocation, setTaskAllocation] = useState("")
  const [reviewAllocation, setReviewAllocation] = useState("")
  const [pseudoname, setPseudoName] = useState("")
  const [handleClicked, setHandleClicked] = useState(false)
  const [isEditing, setIsEditing] = useState(false);
  const [filterCount, setFilterCount]: any = useState(0)
  const [filterCountApplied, setFilterCountApplied]: any = useState(0)
  const [filterInFocus, setfilterInFocus] = useState('')
  const [appliedFilters, setAppliedFilters] = useState<string[]>([]);
  const [appliedFilterValues, setAppliedFilterValues] = useState<string[]>([]);
  const [searchCheckBox, setSearchCheckBox] = useState("")
  const [showCompletedTask, setShowCompletedTask] = useState(false)
  const [showUnallocatedTask, setShowUnallocatedTask] = useState(false)
  const [byTaskListOpen, setByTaskListOpen] = useState(false)
  const [byReviewListOpen, setByReviewListOpen] = useState(false)
  const [selectedTaskItems, setSelectedTaskItems] = useState<string[]>([]);
  const [taskSearchType, setTaskSearchType] = useState("")
  const [vendorId, setVendorId] = useState("")
  const [options, setOptions] = useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const [openAutoComplete, setOpenAutoComplete] = React.useState(false);
  const [selectedVendor, setSelectedVendor] = useState<any>(null)
  const [selectedTaskType, setSelectedTaskType] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState<string[]>([])
  const [searchTermAutocomplete, setSearchTermAutocomplete] = useState("")
  const [bySelectVendorOpen, setBySelectVendorOpen] = useState(false)
  const [taskNameListOpen, setTaskNameListOpen] = useState(false)
  const [taskEmailNameListOpen, setTaskEmailNameListOpen] = useState(false)
  const [reviewerListOpen, setReviewerListOpen] = useState(false)
  const [pseudoListOpen, setPseudoListOpen] = useState(false)
  const [openList, setOpenList] = useState<string | null>(null);
  const textFieldRef = useRef(null);
  const [pagesSearchByInput, setPagesSearchByInput] = useState(0)
  const [openTaskUnallocationModel, setOpenTaskUnallocationModel]: any = useState(false)
  const [openReviewUnallocationModel, setOpenReviewUnallocationModel]: any = useState(false)
  const updateCompletedTaskStatus = useSelector((state: RootState) => state.taskCompleted.updateCompletedTaskStatus)
  const { radio, content, checkbox } = useSelector((state: RootState) => state.isEditedUnsaved);
  // console.log({radio, content, checkbox})
  // const handleNextButton = () => {
  //   setOpen(false)
  // }

  useEffect(() => {
    const handleCreateStatus = () => {
      if (createStatus === STATUS.SUCESS) {
        dispatch(getUpload(
          {
            project_id: id || '',
            pageSize: itemsPerPage
          }))
      }

      if (createStatus === STATUS.FAILED) {
        handleSnackbar('Error Occurred, Please try again!', 'error');
      }
    };

    handleCreateStatus();
    return () => {
      if (createStatus === STATUS.SUCESS) {
        dispatch(setCreateAllocationStatus(STATUS.NOT_STARTED));
      }
    };
  }, [createStatus, navigate, dispatch])


  useEffect(() => {
    const handleCreateStatus = () => {
      if (updateCompletedTaskStatus === STATUS.SUCESS) {
        // handleSnackbar('Success', 'success');
        dispatch(getUpload(
          {
            project_id: id || '',
            pageSize: itemsPerPage
          }))
      }

      if (updateCompletedTaskStatus === STATUS.FAILED) {
        handleSnackbar('Error Occurred, Please try again!', 'error');
      }
    };

    handleCreateStatus();
    return () => {
      if (updateCompletedTaskStatus === STATUS.SUCESS || updateCompletedTaskStatus === STATUS.FAILED) {
        dispatch(setCreateAllocationStatus(STATUS.NOT_STARTED));
      }
    };
  }, [updateCompletedTaskStatus, navigate, dispatch])

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleTaskNameToggle = (listName: string) => {
    setTaskNameListOpen(!taskNameListOpen);
    setfilterInFocus("task_name");
    setOpenList((prev) => (prev === listName ? null : listName))
  };
  const resetTaskName = () => {
    setTaskName("");

  };

  const handleTaskEmailNameToggle = (listName: string) => {
    setTaskEmailNameListOpen(!taskEmailNameListOpen);
    setfilterInFocus("task_allocation");
    setOpenList((prev) => (prev === listName ? null : listName))
  };
  const resetTaskEmailName = () => {
    setTaskAllocation("");
  };

  const handleReviewerNameToggle = (listName: string) => {
    setReviewerListOpen(!reviewerListOpen);
    setfilterInFocus("review_allocation");
    setOpenList((prev) => (prev === listName ? null : listName))
  };
  const resetReviewAllocation = () => {
    setReviewAllocation("");
  };

  const handlePseudoNameToggle = (listName: string) => {
    setPseudoListOpen(!pseudoListOpen);
    setfilterInFocus("pseudo_name");
    setOpenList((prev) => (prev === listName ? null : listName))
  }
  const resetPseudoName = () => {
    setPseudoName("");
  };

  const resetTaskType = () => {
    setSelectedTaskType(null);
    setByTaskListOpen(false)
  }
  const resetStatus = () => {
    setSelectedStatus([])
    setByReviewListOpen(false)
  }
  const resetSelectVendor = () => {
    setSearchTermAutocomplete("")
    setSelectedVendor(null)
    setBySelectVendorOpen(false)
  }

  // List Open Close
  const handleByTaskListClick = (listName: string) => {
    setByTaskListOpen(!byTaskListOpen)
    setfilterInFocus("by_task_list_open");
    setOpenList((prev) => (prev === listName ? null : listName))
  }
  const handleByReviewListClick = (listName: string) => {
    setByReviewListOpen(!byReviewListOpen)
    setfilterInFocus("by_review_list_open");
    setOpenList((prev) => (prev === listName ? null : listName))
  }
  const handleBySelectVendorClick = (listName: string) => {
    setBySelectVendorOpen(!bySelectVendorOpen)
    setfilterInFocus("by_select_vendor_open");
    setOpenList((prev) => (prev === listName ? null : listName))
  }

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };


  const handleMenuClick = (event: any) => {
    setAnchorE2(event.currentTarget);
  };

  const handleMenuItemClick = (option: string) => {
    switch (option) {
      case 'Task Allocation':
        handleOpenVideoModal(option);
        break;
      case 'Task Unallocation':
        handleOpenVideoModal(option);
        break;
      case 'Review Allocation':
        handleOpenVideoModal(option);
        break;
      case 'Review Unallocation':
        handleOpenVideoModal(option);
        break;
      case 'Download Task Data':
        handleDownloadBtn();
        break;
      case 'Upload Task':
        handleAddClick();
        break;
      case 'Delivered Task':
        handleDeliveredAddClick();
        break;
      default:
        break;
    }
  };

  const handleMenuClose = () => {
    setAnchorE2(null);
  };

  useEffect(() => {
    dispatch(getTaskers({ projectId: id || '' }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getUpload(
      {
        search: searchTerm,
        project_id: id || '',
        page: currentPage,
        ...(taskFilterTypesMenuItems.map(item => item.value).includes(type) && { type: type }),
        ...(taskSearchTypesMenuItems.map(item => item.value).includes(type) && searchTerm && { type: type }),
        pageSize: itemsPerPage
      }
    ));
  }, [dispatch, searchTerm, id, currentPage, itemsPerPage, searchTerm, type]);

  useEffect(() => {
    if (type == 'get_completed_tasks' || type == 'get_unallocated_tasks') {
      dispatch(getUpload({ search: searchTerm, project_id: id || '', page: currentPage, type: type, pageSize: itemsPerPage }));
    }
  }, [type])

  const handleOpenInfoModal = () => {
    setAllocationType('TASK')
    setOpenInfoModal(true);
  };

  const redirectingAllocationPage = (option?: string) => {
    // console.log(option)
    navigate(`/allocation/${id}/${option}`);
  };

  const handleOpenVideoModal = (option: string) => {
    if (option === 'Task Allocation') {
      redirectingAllocationPage(option);
    }
    if (option === 'Task Unallocation') {
      redirectingAllocationPage(option);
    }
    if (option === 'Review Allocation') {
      redirectingAllocationPage(option);
    }
    if (option === 'Review Unallocation') {
      redirectingAllocationPage(option);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);

    if (allocationType === 'TASK') {
      dispatch(clearEmails());
    } else {
      dispatch(clearEmailss());
    }

    if (allocationType === 'TASK') {
      setOpenInfoModal(true)
    } else {
      setOpenInfoModalReview(true)
    }
  };

  const handleCloseInfoModal = () => {
    setOpenInfoModal(false);
  };

  const handleCloseInfoModalReview = () => {
    setOpenInfoModalReview(false);
  };

  const handleCancelConfirmation = () => {
    setOpenConfirmationDialog(false);
    setOpenModal(true)
  };

  const handleEmailClick = (email: string) => {
    setSelectedEmail(email);
  };

  const handleAllocateClick = () => {
    setOpenConfirmationDialog(true)
    setOpenModal(false)
    setOpenInfoModal(false)
    setOpenInfoModalReview(false)
  };

  const handleConfirmAllocation = () => {
    const payload = {
      project_id: id,
      task_ids: allocationType === 'TASK' ?
        selectedTasksWithoutAllocation.map((task) => task.id)
        : allocationType === 'REVIEW' ?
          selectedTasksWithAllocation
            .filter(task =>
              selectedReviewsWithoutAllocation.some(review => review.id === task.id)
            )
            .map(task => task.id)
          : [],
      expert_ids: allocationType === 'TASK' ? selectedEmails : selectedEmailsReview,
      type: allocationType,
      deadline: deadline,
      action: "assign"
    }
    dispatch(createAllocation(payload))
    handleCloseModal();
    setOpenInfoModal(false);
    setOpenInfoModalReview(false);
    setOpenConfirmationDialog(false)
  }

  useEffect(() => {
    const handleCreateStatus = () => {
      if (createStatus === STATUS.SUCESS) {
        // handleSnackbar('Success', 'success');
        // window.location.reload()
        dispatch(getUpload({ search: searchTerm, project_id: id || '', pageSize: itemsPerPage, page: currentPage, type: type }))

      }

      if (createStatus === STATUS.FAILED) {
        handleSnackbar('Error Occurred, Please try again!', 'error');
      }
    };

    handleCreateStatus();
    return () => {
      if (createStatus === STATUS.SUCESS) {
        dispatch(setCreateAllocationStatus(STATUS.NOT_STARTED));
      }
    };
  }, [createStatus, navigate, dispatch]);

  useEffect(() => {
    const handleGetStatus = () => {
      if (getStatus === STATUS.PENDING) {
        setLoading(true)
      }
      if (getStatus === STATUS.SUCESS) {
        setLoading(false)
      }
      if (getStatus === STATUS.FAILED) {
        setLoading(false)
      }
    };

    handleGetStatus();
  }, [getStatus])

  const handleNextClick = () => {
    setOpenModal(true);
  };

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1)
    setSearchTerm(event.target.value);
  };
  const handleSearchChangeAutoComplete = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTermAutocomplete(event.target.value)
  }

  const cancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (userId: string) => {
    setUserIdToDelete(userId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    setShowDeleteModal(false);
    try {
      await dispatch(updateUpload({ id: userIdToDelete, is_deleted: true }));
      dispatch(getUpload({ search: searchTerm, project_id: id || '', pageSize: itemsPerPage, page: currentPage }));
    } catch (error) {
      console.error('Failed to delete config', error);
    }
  };

  useEffect(() => {
    if (allocationType === 'TASK') {
      setFilteredTaskers(
        taskers.filter((tasker: { name: string; email: string; }) =>
        (tasker.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          tasker.email?.toLowerCase().includes(searchQuery.toLowerCase()))
        )
      );
    } else if (allocationType === 'REVIEW') {
      setFilteredReviewers(
        reviewers.filter((reviewer: { name: string; email: string; }) =>
        (reviewer.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          reviewer.email?.toLowerCase().includes(searchQuery.toLowerCase()))
        )
      );
    }
  }, [searchQuery, allocationType, type]);

  const handleAddClick = () => {
    navigate(`/uploadtask/${id}`);
  };

  const handleDeliveredAddClick = () => {
    navigate(`/deliveredTask/${id}`);
  };

  const handleEditClick = (row: any) => {
    // Handle edit click
  };

  const handleChangeRowsPerPage = (value: number) => {
    dispatch(getUpload({ search: searchTerm, page: 1, pageSize: value as number, project_id: id || "", type: type }));
    setItemsPerPage(value as number);
    setCurrentPage(1);
    // dispatch(getUpload({ search: searchTerm, page: 1, pageSize: event.target.value as number, project_id: id || "" }));
  };

  const headers =
    [
      "TASK NAME",


      ((
        association_type_vendor &&
        (
          (
            Object.keys(fetchPermissions).includes('task_allocation_single') &&
            fetchPermissions['task_allocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_allocation_bulk') &&
            fetchPermissions['task_allocation_bulk'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_unallocation_single') &&
            fetchPermissions['task_unallocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_unallocation_bulk') &&
            fetchPermissions['task_unallocation_bulk'][0] === true
          )
        )
      ) ||
        (
          association_type_company &&
          (
            Object.keys(fetchPermissions).includes('task_allocation') &&
            fetchPermissions['task_allocation'][0] == true
          )
        )
      ) ? "TASK STATUS" : undefined,


      ((
        association_type_vendor &&
        (
          (
            Object.keys(fetchPermissions).includes('review_allocation_single') &&
            fetchPermissions['review_allocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('review_unallocation_single') &&
            fetchPermissions['review_unallocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('review_unallocation_single') &&
            fetchPermissions['review_unallocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('review_unallocation_bulk') &&
            fetchPermissions['review_unallocation_bulk'][0] === true
          )
        )
      ) ||
        (
          association_type_company &&
          (
            Object.keys(fetchPermissions).includes('review_allocation') &&
            fetchPermissions['review_allocation'][0] == true
          )
        )

      ) ? "REVIEW STATUS" : undefined,

      ((
        association_type_vendor &&
        (
          (
            Object.keys(fetchPermissions).includes('task_allocation_single') &&
            fetchPermissions['task_allocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_allocation_bulk') &&
            fetchPermissions['task_allocation_bulk'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_unallocation_single') &&
            fetchPermissions['task_unallocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_unallocation_bulk') &&
            fetchPermissions['task_unallocation_bulk'][0] === true
          )
        )
      ) ||
        (
          association_type_company &&
          (
            Object.keys(fetchPermissions).includes('task_allocation') &&
            fetchPermissions['task_allocation'][0] == true
          )
        )
      ) ? "TASKER PSEUDO NAME" : undefined,

      ((
        association_type_vendor &&
        (
          (
            Object.keys(fetchPermissions).includes('task_allocation_single') &&
            fetchPermissions['task_allocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_allocation_bulk') &&
            fetchPermissions['task_allocation_bulk'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_unallocation_single') &&
            fetchPermissions['task_unallocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_unallocation_bulk') &&
            fetchPermissions['task_unallocation_bulk'][0] === true
          )
        )
      ) ||
        (
          association_type_company &&
          (
            Object.keys(fetchPermissions).includes('task_allocation') &&
            fetchPermissions['task_allocation'][0] == true
          )
        )
      ) ? "TASKER ALLOCATION" : undefined,

      ((
        association_type_vendor &&
        (
          (
            Object.keys(fetchPermissions).includes('review_allocation_single') &&
            fetchPermissions['review_allocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('review_unallocation_single') &&
            fetchPermissions['review_unallocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('review_unallocation_single') &&
            fetchPermissions['review_unallocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('review_unallocation_bulk') &&
            fetchPermissions['review_unallocation_bulk'][0] === true
          )
        )
      ) ||
        (
          association_type_company &&
          (
            Object.keys(fetchPermissions).includes('review_allocation') &&
            fetchPermissions['review_allocation'][0] == true
          )
        )

      ) ? "REVIEW ALLOCATION" : undefined,

      !association_type_vendor ? "ACTIONS" : undefined
    ].filter(header => header != undefined);

  const ByReviewListItems = ["Active", "Completed", "Not Started", "Submitted", "QC Pushback", "Quality Project", "Awaiting Task Update", "Awaiting Review", "Available To Review", "Quality Accepted", "Delivered", "Not Allocated"]
  const totalPages = Math.ceil((upload.count || 0) / itemsPerPage);
  const allSelected = (upload.results || []).length > 0 && (upload.results || []).every(row => selectedIds.some(id => id.id === row.id));

  const handleSelectAllClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleSearchAllocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleDoneClick = () => {
    if (selectedOption === 'One') {
      const allIds = (upload.results || []).map(row => ({ id: row.id, task_name: row.task_name }));
      dispatch(setIds(allIds));
    } else {
      dispatch(setIds([]));
    }
    handleClosePopover();
  };

  const handleSelectOptionChange = (option: string) => {
    setSelectedOption(prevOption => (prevOption === option ? '' : option));
  };

  useEffect(() => {
    handleDoneClick();
  }, [selectedOption]);

  const handleTaskClick = (row: any) => {
    // navigate(`/task/${id}/${row.id}`);
    setDrawer(true)
    setcurrentTaskID(row.id)

  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const handleFilterOpen = () => {
    setOpenFilterList(true)
  }

  const handleDownloadBtn = () => {
    const res: any = dispatch(getTaskData({ project_id: id || '' }));
    // console.log({res})
  }
  const handleFilterClose = () => {
    setOpenFilterList(false)
  }

  const handleClick = (event: any) => {
    setAnchorE3(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE3(null);
    setSearchType("")
  };

  // const handleMenuItemClick = (value: any) => {
  //   setSelectedValue(value);
  //   handleClose(); // Close the menu after selection
  //   setType(value)
  //   // console.log("Selected value:", value); // You can also log or perform any action with the selected value
  // };

  const handleTabClick = (value: any) => {
    setType(value)
  }


  const handleApplyClick = () => {
    let local_type = type
    let local_search_term = searchTerm
    let local_filter_count = 0

    setFilterCountApplied(filterCount)

    if (taskAllocation.length > 0) {
      local_type = "task_allocation"
      local_search_term = taskAllocation
      local_filter_count = local_filter_count + 1
    }

    if (reviewAllocation.length > 0) {
      local_type = "review_allocation"
      local_search_term = reviewAllocation
      local_filter_count = local_filter_count + 1

    }

    if (taskName.length > 0) {
      local_type = "task_name"
      local_search_term = taskName
      local_filter_count = local_filter_count + 1

    }

    if (pseudoname.length > 0) {
      local_type = "pseudo_name"
      local_search_term = pseudoname
      local_filter_count = local_filter_count + 1

    }

    if (vendorId) {
      local_type = local_type + ',' + 'GET_VENDOR_STATUS'
      local_filter_count = local_filter_count + 1
    }
    if (selectedStatus.length > 0) {
      local_type = local_type + ',' + "GET_BY_STATUS";
      local_filter_count = local_filter_count + 1
    }
    if (selectedTaskType != null) {
      local_filter_count = local_filter_count + 1

    }

    dispatch(getUpload({
      search: local_search_term,
      project_id: id || '',
      page: currentPage,
      type: local_type.replace(/^,|,$/g, ''),
      pageSize: itemsPerPage,
      allocated_task_type: selectedTaskType,
      statuses: selectedStatus,
      vendor_id: vendorId,
    }))
    setAnchorE3(null)
    setFilterCountApplied(local_filter_count)
  }

  const handleFieldChange = (action: string, e: any) => {
    switch (action) {
      case "taskName":
        setTaskName(e.target.value);
        break;
      case "alloc":
        setTaskAllocation(e.target.value);
        break;
      case "reviewAllocation":
        setReviewAllocation(e.target.value);
        break;
      case "pseudoName":
        setPseudoName(e.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const filters: Filters = {
      taskName,
      taskAllocation,
      reviewAllocation,
      pseudoname,
      // selectedTaskType,
      // selectedStatus,
    };
    const activeFilters: (keyof Filters)[] = Object.keys(filters).filter((key) => filters[key as keyof Filters] !== "") as (keyof Filters)[];
    const activeFilterValues = Object.values(filters).filter(value => value !== "");

    setFilterCount(activeFilters.length);
    setAppliedFilterValues(activeFilterValues);
  }, [taskName, taskAllocation, reviewAllocation, pseudoname, selectedTaskType, selectedStatus]);

  useEffect(() => {
    dispatch(getVendorAction({
      search_query: searchTerm,
    }));
  }, [dispatch, searchTerm]);

  useEffect(() => {
    if (getVendorStatus === STATUS.SUCESS) {
      setOptions(Vendors.results)
    }
  }, [getVendorStatus])

  useEffect(() => {
    if (getTaskDataStatus === STATUS.FAILED) {
      setSnackbarMessage('Couldn\'t Download File. Please Try Again.')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }

    return () => {
      if (getTaskDataStatus === STATUS.SUCESS) {
        dispatch(setGetTaskDataStatus(STATUS.NOT_STARTED))
      }

    }
  }, [getTaskDataStatus])


  // const handleOpenTaskSwapping = ()  =>{
  //   setOpenTaskSwapping(true);
  //   setAnchorE2(null);
  // }
  const debouncedResults = useMemo(() => debounce(handleSearchChange, 300), []);
  const debouncedResultsForAutoComplete = useMemo(() => { return debounce(handleSearchChangeAutoComplete, 300); }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });
  useEffect(() => {
    return () => {
      debouncedResultsForAutoComplete.cancel();
    };
  });


  const handleAutocompleteInput = (e: any) => {
    debouncedResultsForAutoComplete(e)
  };

  const handleAutoCompleteOpen = () => {
    setOpenAutoComplete(true);
  };

  const handleAutoCompleteClose = () => {
    setOpenAutoComplete(false);
  };


  const handleTaskTypeSelect = (type: any) => {
    setSelectedTaskType(type);
    setByTaskListOpen(false)
  };

  const handleStatusToggle = (status: any) => {
    setSelectedStatus((prev: any) => {
      if (prev.includes(status)) {
        return prev.filter((item: any) => item !== status);
      } else {
        return [...prev, status];
      }
    });
  }

  const handleVendorAllocation = () => {
    navigate(`/vendorbulkallocation/${id}`)
  }
  const handleVendorUnallocation = () => {
    navigate(`/vendorbulkunallocation/${id}`)
  }

  const handleConfirmClose = () => {
    setDrawer(false);
    setIsUnsavedModalOpen(false);
  };

  const handleCancelClose = () => {
    setIsUnsavedModalOpen(false);
  };


  const handleCloseDrawer = () => {
    if (radio || content || checkbox) {
      setIsUnsavedModalOpen(true);
      return;
    }
    else if (!radio && !content && !checkbox) {
      setDrawer(false);
    }
    setDrawer(false);
  };
  console.log(fetchPermissions)

  const handleReset = () => {
    window.location.reload()
  }

  return (
    <>
      <Drawer
        anchor={"right"}
        open={drawer}
        onClose={() => setDrawer(false)}
        PaperProps={{ sx: { width: "50%", padding: "40px" } }}
      >
        <TaskCompleted taskID={currentTaskID} />
      </Drawer>

      <div className={classes.container}>
        <div style={{ flex: 3 }}>
          <SearchBar
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
          />

          {/* <Divider orientation='vertical' variant='inset' flexItem/> */}

          <Popover
            // id={id}
            open={Boolean(anchorE3)}
            anchorEl={anchorE3}
            onClose={handleClose}
            anchorOrigin={{
              horizontal: "left",
              vertical: "bottom",
            }}
          >
            <Box sx={{ width: "400px", typography: "body1", height: "450px" }}>
              <TabContext value={value}>
                <Box
                  sx={{
                    borderBottom: 2,
                    borderColor: "divider",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Filters" value="1" />
                    <div style={{ position: "absolute", left: "130px", top: "5px" }}>
                      <IconButton
                        onClick={handleClose}
                        color="primary"
                        aria-label=""
                        style={{ marginLeft: "14rem" }}
                      >
                        <CancelIcon color="primary" />
                      </IconButton>
                    </div>
                    {/* <Tab label="Tasks Filters" value="2" /> */}
                  </TabList>
                </Box>

                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginLeft: "10px" }}>
                  {/* Task Name List Item */}
                  <div onClick={() => handleTaskNameToggle("taskName")} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ListItemText primary={<h1>Task name</h1>} sx={{ fontSize: '20px', fontWeight: 'bold' }} />
                    {openList === "taskName" ? <ExpandLess /> : <ExpandMore />}
                    <Button onClick={resetTaskName} style={{ padding: 0, minWidth: 'auto' }}>
                      <Tooltip title="Reset task name" arrow>
                        <BlockIcon fontSize="small" color="error" />
                      </Tooltip>
                    </Button>
                  </div>

                  <Collapse in={openList === "taskName"} timeout="auto" unmountOnExit>
                    <div style={{ padding: '14px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      {/* Text Field */}
                      <TextField
                        autoFocus={filterInFocus === "task_name"}
                        id="outlined-basic"
                        value={taskName}
                        label=""
                        variant="outlined"
                        onChange={(e) => handleFieldChange("taskName", e)}
                        style={{ width: "260px", paddingLeft: "20px" }}
                        onBlur={() => setfilterInFocus("")}
                        onFocus={() => setfilterInFocus("task_name")}
                        autoComplete="off"
                      />
                    </div>
                  </Collapse>
                </List>

                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  flexItem
                />

                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginLeft: "10px" }}>
                  {/* Tasker Email/Name List Item */}
                  <div onClick={() => handleTaskEmailNameToggle("taskEmailName")} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ListItemText primary={<h1>Tasker email / name</h1>} sx={{ fontSize: '20px', fontWeight: 'bold' }} />
                    {openList === "task_allocation" ? <ExpandLess /> : <ExpandMore />}
                    <Button onClick={resetTaskEmailName} style={{ padding: 0, minWidth: 'auto' }}>
                      <Tooltip title="Reset tasker name / email" arrow>
                        <BlockIcon fontSize="small" color="error" />
                      </Tooltip>
                    </Button>
                  </div>

                  <Collapse in={openList === "taskEmailName"} timeout="auto" unmountOnExit>
                    <div style={{ padding: '14px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      {/* Text Field */}
                      <TextField
                        autoFocus={filterInFocus === "task_allocation"}
                        id="outlined-basic"
                        value={taskAllocation}
                        label=""
                        variant="outlined"
                        onChange={(e) => handleFieldChange("alloc", e)}
                        style={{ width: "260px", paddingLeft: "20px" }}
                        onBlur={() => setfilterInFocus("")}
                        onFocus={() => setfilterInFocus("task_allocation")}
                        autoComplete="off"
                      />
                    </div>
                  </Collapse>
                </List>

                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  flexItem
                />

                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginLeft: "10px" }}>
                  {/* Reviewer Email/Name List Item */}
                  <div onClick={() => handleReviewerNameToggle("reviewAllocation")} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <ListItemText primary={<h1>Reviewer email / name</h1>} sx={{ fontSize: '20px', fontWeight: 'bold', fontFamily: "montserrat" }} />
                    {openList === "reviewAllocation" ? <ExpandLess /> : <ExpandMore />}
                    <Button onClick={resetReviewAllocation} style={{ padding: 0, minWidth: 'auto' }}>
                      <Tooltip title="Reset reviewer name" arrow>
                        <BlockIcon fontSize="small" color="error" />
                      </Tooltip>
                    </Button>
                  </div>

                  <Collapse in={openList === "reviewAllocation"} timeout="auto" unmountOnExit>
                    <div style={{ padding: '14px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      {/* Text Field */}
                      <TextField
                        autoFocus={filterInFocus === "review_allocation"}
                        id="outlined-basic"
                        value={reviewAllocation}
                        label=""
                        variant="outlined"
                        onChange={(e) => handleFieldChange("reviewAllocation", e)}
                        style={{ width: "260px", paddingLeft: "20px" }}
                        onBlur={() => setfilterInFocus("")}
                        onFocus={() => setfilterInFocus("review_allocation")}
                        autoComplete="off"
                      />
                    </div>
                  </Collapse>
                </List>

                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  flexItem
                />

                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginLeft: "10px" }}>
                  {/* Pseudo Name List Item */}
                  <div onClick={() => handlePseudoNameToggle("pseudoName")} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <ListItemText primary={<h1>Pseudo name</h1>} sx={{ fontSize: '20px', fontWeight: 'bold' }} />
                    {openList === "pseudoName" ? <ExpandLess /> : <ExpandMore />}
                    <Button onClick={resetPseudoName} style={{ padding: 0, minWidth: 'auto' }}>
                      <Tooltip title="Reset pseudo name" arrow>
                        <BlockIcon fontSize="small" color="error" />
                      </Tooltip>
                    </Button>
                  </div>

                  <Collapse in={openList === "pseudoName"} timeout="auto" unmountOnExit>
                    <div style={{ padding: '14px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      {/* Text Field */}
                      <TextField
                        autoFocus={filterInFocus === "pseudo_name"}
                        id="outlined-basic"
                        value={pseudoname}
                        label=""
                        variant="outlined"
                        onChange={(e) => handleFieldChange("pseudoName", e)}
                        style={{ width: "260px", paddingLeft: "20px" }}
                        onBlur={() => setfilterInFocus("")}
                        onFocus={() => setfilterInFocus("pseudo_name")}
                        autoComplete="off"
                      />
                    </div>
                  </Collapse>
                </List>

                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  flexItem
                />

                <List
                  sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginLeft: "10px" }}
                  component="div"
                >
                  {/* Task Type Section */}
                  <div style={{ display: "flex", cursor: "pointer", justifyContent: "space-around" }} onClick={() => handleByTaskListClick("taskList")} >
                    <ListItemText primary={<h1>Task Type</h1>} sx={{ fontSize: '20px', fontWeight: 'bold' }} />
                    {openList === "taskList" ? <ExpandLess /> : <ExpandMore />}
                    <Button onClick={resetTaskType} style={{ padding: 0, minWidth: 'auto' }}>
                      <Tooltip title="Reset task type" arrow>
                        <BlockIcon fontSize="small" color="error" />
                      </Tooltip>
                    </Button>
                  </div>

                  <Collapse in={openList === "taskList"} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {['Task', 'Review'].map((type) => (
                        <ListItemButton key={type} sx={{ pl: 4 }} onClick={() => handleTaskTypeSelect(type)}>
                          <ListItemIcon>  
                            <Radio
                              edge="start"
                              checked={selectedTaskType === type}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary={type} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </List>

                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  flexItem
                />

                {/* Status Section */}
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginLeft: "10px" }}
                  component="div"
                >
                  <div style={{ display: "flex", cursor: "pointer", justifyContent: "space-between", alignItems: "center" }} onClick={() => handleByReviewListClick("status")}>
                    <ListItemText primary={<h1>Status</h1>} sx={{ fontSize: '20px', fontWeight: 'bold' }} />
                    {openList === "status" && byReviewListOpen ? <ExpandLess /> : <ExpandMore />}
                    <Button onClick={resetStatus} style={{ padding: 0, minWidth: 'auto' }}>
                      <Tooltip title="Reset status" arrow>
                        <BlockIcon fontSize="small" color="error" />
                      </Tooltip>
                    </Button>
                  </div>
                  <Collapse in={openList === "status"} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {ByReviewListItems.map((item, index) => (
                        <ListItemButton key={index} sx={{ pl: 4 }} onClick={() => handleStatusToggle(item)}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={selectedStatus.includes(item)}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary={item} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>

                </List>

                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  flexItem
                  style={{ marginBottom: "5px" }}
                />

                <List disablePadding sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginLeft: "10px" }}
                  component="div">
                  <div style={{ display: "flex", cursor: "pointer", justifyContent: "space-between", alignItems: "center" }} onClick={() => handleBySelectVendorClick("selectVendor")}>
                    <ListItemText primary={<h1>Select Vendor</h1>} sx={{ fontSize: '20px', fontWeight: 'bold' }} />
                    {openList === "selectVendor" ? <ExpandLess /> : <ExpandMore />}
                    <Button onClick={resetSelectVendor} style={{ padding: 0, minWidth: 'auto' }}>
                      <Tooltip title="Reset vendor">
                        <BlockIcon fontSize="small" color="error" />
                      </Tooltip>
                    </Button>
                  </div>
                  <Collapse in={openList === "selectVendor"} timeout="auto" unmountOnExit>
                    <div style={{ marginLeft: "20px" }}>
                      <Autocomplete
                        sx={{ width: 330 }}
                        open={openAutoComplete}
                        onOpen={handleAutoCompleteOpen}
                        onClose={handleAutoCompleteClose}
                        // disabled={selectedRow}
                        autoSelect
                        value={selectedVendor}
                        getOptionLabel={(option) =>
                          option.vendor_pseudo_name ?
                            `${option.vendor_name} (${option.vendor_pseudo_name})`
                            : option.vendor_name
                        }
                        options={options}
                        loading={getStatus === STATUS.PENDING}
                        onChange={(event, value) => {
                          if (value) {
                            setVendorId(value.vendor_id);
                            setSelectedVendor(value);
                          } else {
                            setSelectedVendor(null);
                          }
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              placeholder="Select Vendor name"
                              size="small"
                              sx={{ width: '100%' }}
                              value={selectedVendor != null ? selectedVendor.name : ''}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                              onChange={(e) => { handleAutocompleteInput(e) }}
                            />
                          )
                        }}
                      />
                    </div>
                  </Collapse>
                </List>

              </TabContext>

              <Divider
                orientation="horizontal"
                variant="fullWidth"
                flexItem
                style={{ marginBottom: "10px", marginTop: "5px" }}
              />

              {/* Apply Button  */}

              <div
                style={{
                  backgroundColor: "white",
                  position: "sticky",
                  bottom: 0,
                  zIndex: 1,
                  height: "60px",
                  paddingTop: "10px",
                }}
              >
                <Button
                  style={{
                    position: "sticky",
                    bottom: "0",
                    left: "276px",
                    zIndex: 1,
                    top: "50px",
                  }}
                  variant="contained"
                  startIcon={<FilterAltIcon />}
                  onClick={handleApplyClick}
                >
                  Apply
                  {/* {filterCount > 0 ? (filterCount) : (null)} */}
                </Button>
              </div>
            </Box>
          </Popover>
        </div >

        <div style={{ flex: 3 }} className={classes.actionsContainer}>
          <PaginationEntries
            itemsPerPage={itemsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <Badge badgeContent={filterCountApplied} color="error">

              <IconButton
                color="primary"
                aria-label="filter options"
                onClick={handleClick}
                value={selectedValue}
                autoFocus={false}
                sx={{
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  background: "linear-gradient(45deg, #42a5f5, #478ed1)",
                  borderRadius: "30%",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    background: "linear-gradient(45deg, #478ed1, #42a5f5)",
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Tooltip title={
                  appliedFilterValues.length > 0 ? (
                    <List style={{ padding: "5px" }}>
                      {appliedFilterValues.map((filter, index) => (
                        <ListItem key={index} style={{ padding: "0px" }}>
                          <Typography variant="body1">{filter}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    "No filters applied"
                  )
                }
                  placement="bottom-end"
                  arrow>
                  <FilterAltIcon fontSize="medium" sx={{ color: "white" }} />
                </Tooltip>
              </IconButton>
            </Badge>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            

              <Tooltip title="Reset" placement="bottom-end" arrow>
              <IconButton
                color="primary"
                aria-label="filter options"
                onClick={handleReset}
                value={selectedValue}
                autoFocus={false}
                sx={{
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  background: "linear-gradient(45deg, #42a5f5, #478ed1)",
                  borderRadius: "30%",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    background: "linear-gradient(45deg, #478ed1, #42a5f5)",
                    transform: "scale(1.1)",
                  },
                }}
              >
                  <RestoreOutlinedIcon fontSize="medium" sx={{ color: "white" }} />
              </IconButton>
                </Tooltip>
            
          </Box>

          {/* <AddButton onClick={handleAddClick}>
            <FileUploadIcon /> Upload Task
          </AddButton>
          <Button
            variant="contained"
            disableElevation
            onClick={handleOpenInfoModal}
            sx={{
              width: "auto",
              height: "40px",
              marginTop: "10px",
              borderRadius: "8px 0 0 8px",
              padding: "8px, 16px, 8px, 16px",
              backgroundColor: "#3D85C6",
              textTransform: "none",
              marginLeft: "10px",
              "&:hover": {
                backgroundColor: "#3D85C6",
              },
            }}
          >
            <AddTaskIcon sx={{ marginRight: "10px" }} />
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "24px",
                textTransform: "none",
                fontWeight: "600",
              }}
            >
              Bulk Task Allocation
            </Typography>
          </Button>
          <Divider orientation="vertical" flexItem sx={{ height: "1px" }} /> */}
          <IconButton
            // fontSize="small"
            onClick={handleMenuClick}
            sx={{
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              background: "linear-gradient(45deg, #42a5f5, #478ed1)",
              borderRadius: "30%",
              transition: "transform 0.3s ease-in-out",
              width: "40px",
              height: "40px",
              marginTop: "8px",
              color: "white",
              cursor: "pointer",
              "&:hover": {
                background: "linear-gradient(45deg, #478ed1, #42a5f5)",
                transform: "scale(1.1)",
              },
            }}
          >
            {getTaskDataStatus === STATUS.PENDING ? <CircularProgress size={17} /> : <MoreVertIcon style={{ color: "white" }} />}
          </IconButton>
          {/* <AddButton onClick={handleMenuClick}>Actions</AddButton> */}
          <Divider orientation="vertical" flexItem sx={{ height: '1px' }} />
          <Menu
            id="invite-menu"
            anchorEl={anchorE2}
            open={Boolean(anchorE2)}
            onClose={handleMenuClose}
          >
            {association_type_company && (
              <>
                <MenuItem onClick={() => handleVendorAllocation()}>Vendor Bulk Allocation</MenuItem>
                <MenuItem onClick={() => handleVendorUnallocation()}>Vendor Bulk Unallocation</MenuItem>
                {/* {fetchPermissions.vendor_unallocation ?.[0] && <MenuItem disabled={!fetchPermissions.vendor_unallocation?.[0]} onClick={() => handleMenuItemClick('Vendor Unallocation')}>Bulk Vendor Unallocation</MenuItem>} */}
                {fetchPermissions.task_allocation?.[0] && <MenuItem disabled={!fetchPermissions.task_allocation?.[0]} onClick={() => handleMenuItemClick('Task Allocation')}>Bulk Task Allocation</MenuItem>}
                {fetchPermissions.task_allocation?.[0] && <MenuItem disabled={!fetchPermissions.task_allocation?.[0]} onClick={() => handleMenuItemClick('Task Unallocation')}>Bulk Task Unallocation</MenuItem>}
                {fetchPermissions.review_allocation?.[0] && <MenuItem disabled={!fetchPermissions.review_allocation?.[0]} onClick={() => handleMenuItemClick('Review Allocation')}>Bulk Review Allocation</MenuItem>}
                {fetchPermissions.review_allocation?.[0] && <MenuItem onClick={() => handleMenuItemClick('Review Unallocation')}>Bulk Review Unallocation</MenuItem>}
                {fetchPermissions.export_data?.[0] && <MenuItem disabled={!fetchPermissions.export_data?.[0]} onClick={() => handleMenuItemClick('Download Task Data')}>Download Task Data</MenuItem>}
                <MenuItem onClick={() => handleMenuItemClick('Upload Task')}>Upload Task</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('Delivered Task')}>Mark Task as Delivered</MenuItem>
              </>
            )}
            {association_type_vendor && (
              <>
                {/* {fetchPermissions.vendor_unallocation ?.[0] && <MenuItem disabled={!fetchPermissions.vendor_unallocation?.[0]} onClick={() => handleMenuItemClick('Vendor Unallocation')}>Bulk Vendor Unallocation</MenuItem>} */}
                {fetchPermissions.task_allocation_bulk?.[0] && <MenuItem disabled={!fetchPermissions.task_allocation_bulk?.[0]} onClick={() => handleMenuItemClick('Task Allocation')}>Bulk Task Allocation</MenuItem>}
                {fetchPermissions.task_unallocation_bulk?.[0] && <MenuItem disabled={!fetchPermissions.task_unallocation_bulk?.[0]} onClick={() => handleMenuItemClick('Task Unallocation')}>Bulk Task Unallocation</MenuItem>}
                {fetchPermissions.review_allocation_bulk?.[0] && <MenuItem disabled={!fetchPermissions.review_allocation_bulk?.[0]} onClick={() => handleMenuItemClick('Review Allocation')}>Bulk Review Allocation</MenuItem>}
                {fetchPermissions.review_unallocation_bulk?.[0] && <MenuItem onClick={() => handleMenuItemClick('Review Unallocation')}>Bulk Review Unallocation</MenuItem>}
                {fetchPermissions.export_data?.[0] && <MenuItem disabled={!fetchPermissions.export_data?.[0]} onClick={() => handleMenuItemClick('Download Task Data')}>Download Task Data</MenuItem>}
              </>
            )}
          </Menu>
        </div>
      </div >
      <TableContainer>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!loading && (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: "#F7F9FA",
                "&:first-child td, &:first-child th": { borderBottom: 0 },
              }}
            >
              {/* <TableRow>
                <TableCellHead>
                  <Tooltip
                    title={allSelected
                      ? `This will deselect all ${upload.results.length} tasks`
                      : `This will select all ${upload.results.length} tasks`}
                    arrow
                  >
                  <Checkbox
                    checked={allSelected}
                    onClick={handleSelectAllClick}
                  />
                  </Tooltip>
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <div>
                      <List>
                        <ListItem
                          selected={selectedOption === "One"}
                          onClick={() => handleSelectOptionChange("One")}
                          sx={{ cursor: "pointer" }}
                        >
                          <ListItemText
                            primary={`Select ${upload && upload.results && upload.results.length
                              } tasks`}
                          />
                        </ListItem>
                      </List>
                      <Tooltip title='Currently, this option is not availble' arrow placement='bottom'>
                    <div
                      style={{
                        
                        backgroundColor: '#A9A9A9',
                        cursor: 'pointer'
                      }}
                      // onClick={() => handleSelectOptionChange('All')}
                    >
                      Select all tasks
                    </div>
                    </Tooltip>
                    </div>
                  </Popover>
                </TableCellHead> */}
              {headers.map((header) => (
                <TableCellHead key={header}>{header}</TableCellHead>
              ))}
              {/* </TableRow> */}
            </TableHead>
            <TableBody>
              {getStatus === STATUS.SUCESS &&
                upload &&
                upload.results &&
                upload.results.length > 0 ? (
                upload.results.map((row: any) => (
                  <>
                    <TableRowItem
                      key={row.id}
                      row={row}
                      onEditClick={handleEditClick}
                      onDeleteClick={handleDeleteClick}
                      handleClick={() => handleTaskClick(row)}
                    />
                  </>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    className={classes.noUsersMessage}
                  >
                    <Error
                      message={
                        "There are no tasks added as of yet. You can add a task to see it here."
                      }
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <Dialog
        open={isUnsavedModalOpen}
        onClose={handleCancelClose}
      >
        <div style={{ padding: '16px', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h6" gutterBottom style={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px', lineHeight: '18px', display: 'flex', alignItems: 'center', color: '#000000' }}>
                Unsaved Changes
              </Typography>
            </div>
          </div>
          <Divider style={{ marginBottom: '10px' }} />
          <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '14px', lineHeight: '18px', color: '#000000', marginTop: '30px' }}>
            You have unsaved changes. Are you sure you want to leave?
          </Typography>

          <DialogActions>
            <Button
              sx={{
                minWidth: '70px',
                minHeight: '40px',
                backgroundColor: '#F7F9FA',
                padding: '8px 16px',
                gap: '4px',
                ml: '60%',
                borderRadius: '8px',
                '&:hover': { backgroundColor: '#E0E0E0' },
              }}
              onClick={handleCancelClose}
            >
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  fontSize: '14px',
                  color: '#10383D',
                  textTransform: 'none',
                }}
              >
                No
              </Typography>
            </Button>
            <Button
              sx={{
                minWidth: '66px',
                minHeight: '40px',
                backgroundColor: '#3D85C6',
                padding: '8px 16px',
                gap: '4px',
                borderRadius: '8px',
                '&:hover': { backgroundColor: '#3D85C6' },
              }}
              onClick={handleConfirmClose}
            >
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  fontSize: '14px',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
              >
                Yes
              </Typography>
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={paginate}
        itemsPerPage={itemsPerPage}
      />
      <DeleteModal
        open={showDeleteModal}
        onCancel={cancelDelete}
        onConfirm={confirmDelete}
      />
      <AllocationModal
        open={openModal}
        onClose={handleCloseModal}
        onEmailClick={handleEmailClick}
        taskers={
          allocationType === "TASK" ? filteredTaskers : filteredReviewers
        }
        selectedEmail={selectedEmail}
        searchQuery={searchQuery}
        onSearchChange={handleSearchAllocation}
        onAllocateClick={handleAllocateClick}
        isCheckboxMode={true}
        selectedIds={selectedIds}
        allocationType={allocationType}
      // taskers={taskers}
      // onSearchChange={handleSearchAllocation}
      // searchQuery={searchQuery}
      />
      <InfoModal
        open={openInfoModal}
        onClose={handleCloseInfoModal}
        selectedIds={selectedIds}
        onNextClick={handleNextClick}
      />
      <InfoModalReview
        open={openInfoModalReview}
        onClose={handleCloseInfoModalReview}
        selectedIds={selectedIds}
        onNextClick={handleNextClick}
      />


      <InfoModelTaskUnallocation
        open={open} setOpen={setOpen}
        openTaskUnallocationModel={openTaskUnallocationModel}
        openReviewUnallocationModel={openReviewUnallocationModel}
        setOpenTaskUnallocationModel={setOpenTaskUnallocationModel}
        setOpenReviewUnallocationModel={setOpenReviewUnallocationModel} />


      <ConfirmationDialog
        open={openConfirmationDialog}
        onClose={handleCancelConfirmation}
        onConfirm={handleConfirmAllocation}
      />
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default TaskUploadTable;
