import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import TaskDetailsAccordion from './accordianDetails';
import { getCompletedTask } from '../../redux/slices/taskCompleted';
import { useParams } from 'react-router-dom';
import { STATUS } from '../../utils/status';
import { CircularProgress } from '@mui/material';

export default function AccordionUsage({taskID}:{taskID:string}) {
    const taskDetails = useSelector((state: RootState) => state.taskCompleted.taskCompleted.results);
    const dispatch = useDispatch<AppDispatch>();
    const {id} = useParams();
    const { getStatus } = useSelector((state:RootState) => state.taskCompleted)
    

    useEffect(()=>{
        dispatch(getCompletedTask({project_id: id, task_id: taskID}))
    },[dispatch, id, taskID])

    
    

    return (
        (
            getStatus === STATUS.PENDING ? 
                <div style={{textAlign:'center'}} >
                    <CircularProgress />
                </div> 
            :   getStatus === STATUS.SUCESS ? 
                    taskDetails && taskDetails.length === 0 ?  
                        <div style={{display: "flex", justifyContent:"center", alignItems: "center", fontWeight: "bold", fontSize: "24px", height: "100%"}}>Task Not Completed</div> 
                        : <div>
                                {taskDetails &&taskDetails.filter((task: any) => task.type === 'TASK').map((result, resultIndex) => (
                                    <TaskDetailsAccordion key={resultIndex} result={result} resultIndex={resultIndex} />
                                ))}
                            
                                {taskDetails &&taskDetails.filter((task: any) => task.type === 'REVIEW').map((result, resultIndex) => (
                                    <TaskDetailsAccordion key={resultIndex} result={result} resultIndex={resultIndex} />
                                ))}
                            
                        </div> 
            :  getStatus === STATUS.FAILED ?
                <div>Failed to Fetch the Details. Please contact admin.</div>
            : <></>
        )
    );
}
