import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { AnalyticsCompletedState, AnalyticsPayload } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/analyticsCompletedExtraReducer';

const initialState: AnalyticsCompletedState = {
  analyticsCompleted: { 
    count: undefined,
    results: [] 
  },
  error: null,
  getStatus: STATUS.NOT_STARTED,
};

export const getCompletedAnalytics = createAsyncThunk(
    '/task/company/analytics/by_date/get',
    async (payload: AnalyticsPayload = {}, thunkAPI) => {
      const { search, page, pageSize, sort_by, sort_order, project_id, type } = payload;
      const queryString = new URLSearchParams({
        ...(search && { search }),
        ...(page && { page: page.toString() }),
        ...(pageSize && { page_size: pageSize.toString() }),
        ...(sort_by && { sort_by }),
        ...(sort_order && { sort_order }),
        ...(project_id && { project_id }),
        ...(type && {type}),
      }).toString();
      const response = await api.get(`/task/company/analytics/by_date?${queryString}`);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

const analyticsCompletedSlice = createSlice({
  name: 'analyticsCompleted',
  initialState,
  reducers: {
  },
  extraReducers
});

export default analyticsCompletedSlice.reducer;
