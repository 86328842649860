import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { AnalyticsPayload, TaskCompletedState } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/taskCompletedExtraReducer';

const initialState: TaskCompletedState = {
  taskCompleted: {
    count: undefined,
    results: []
  },
  error: null,
  getStatus: STATUS.NOT_STARTED,
  updateCompletedTaskStatus: STATUS.NOT_STARTED
};

export const getCompletedTask = createAsyncThunk(
  'task/company/completed-task/get',
  async (payload: AnalyticsPayload = {}, thunkAPI) => {
    const { search, page, pageSize, sort_by, sort_order, id, project_id, task_id } = payload;
    const queryString = new URLSearchParams({
      ...(id && { id }),
      ...(search && { search }),
      ...(page && { page: page.toString() }),
      ...(pageSize && { page_size: pageSize.toString() }),
      ...(sort_by && { sort_by }),
      ...(sort_order && { sort_order }),
      ...(project_id && { project_id }),
      ...(task_id && { task_id })
    }).toString();
    const response = await api.get(`task/company/completed-task/?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const updateCompletedTask = createAsyncThunk(
  'allocation/response/action/new/post',
  async (payload: any, thunkAPI) => {
    const response = await api.post(`task/allocation/response/action/new/`, payload);
    const { status, data } = response;
    if (status === 201) {
      return {payload, data};
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const addTaskCompletedSlice = createSlice({
  name: 'taskCompleted',
  initialState,
  reducers: {},
  extraReducers
});

export default addTaskCompletedSlice.reducer;
