import React, { useEffect, useState } from 'react'
import TaskCompletedList from './taskCompletedList'
import AnalyticsListTable from './expertAnalyticsList'
import { Paper, ToggleButton, SelectChangeEvent, ToggleButtonGroup, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../redux/store/appStore'
import { useNavigate, useParams } from 'react-router-dom'
import { getAnalytics } from '../../redux/slices/analytics'
import { getExpertAnalytics } from '../../redux/slices/analytics/experts'
// import { SearchBar } from '../inputField/textfield'
// import { getUpload } from '../../redux/slices/upload'
// import PaginationEntries from '../pagination/paginationEntries'


const AnalyticsMain = () => {
  const analytics = useSelector((state: RootState) => state.analytics.analytics)
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const [tableSwitch, setTableSwitch] = useState("TASK")


  const handleChangeToggle = (event:any , table: string,) => {
    if (table !== null) {
      setTableSwitch(table);
      // navigate(`/project/${id}?tab=${newAlignment}`);
      dispatch(getExpertAnalytics({ project_id: id, type: event.target.value }))
    }
  };

  useEffect(() => {
    dispatch(getAnalytics({ project_id: id }))
  }, [dispatch, id])

  // const [searchTerm, setSearchTerm] = useState('');
  // const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  // const [currentPage, setCurrentPage] = useState(1);



  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchTerm(event.target.value);
  //   console.log(event.target.value)
  // };

  // const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
  //   setItemsPerPage(event.target.value as number);
  //   setCurrentPage(1);
  //   dispatch(getUpload({ search: searchTerm, page: 1, pageSize: event.target.value as number, project_id: id || "" }));
  // };

  // useEffect(()=>{
  //   dispatch(getAnalytics({project_id: id}))
  // },[dispatch, id])

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4%' }}>
        <Paper
          elevation={3}
          style={{
            marginRight: '20px',
            width: '22%',
            height: '100px',
            padding: '10px',
            background: 'linear-gradient(45deg, #42a5f5, #478ed1)',
            color: '#fff',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <p style={{ margin: 0, fontSize: '24px', fontWeight: 'bold' }}>
              {analytics && analytics.total_tasks_added ? analytics.total_tasks_added : '0'}
            </p>
            <h3 style={{ margin: 0, fontSize: '16px', fontWeight: '500' }}>Total Task Added</h3>
          </div>
        </Paper>
        <Paper
          elevation={3}
          style={{
            marginRight: '20px',
            width: '22%',
            height: '100px',
            padding: '10px',
            background: 'linear-gradient(45deg, #ffa726, #fb8c00)',
            color: '#fff',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <p style={{ margin: 0, fontSize: '24px', fontWeight: 'bold' }}>
              {analytics && analytics.total_tasks_allocated ? analytics.total_tasks_allocated : '0'}
            </p>
            <h3 style={{ margin: 0, fontSize: '16px', fontWeight: '500' }}>Total Task Allocated</h3>

          </div>
        </Paper>
        <Paper
          elevation={3}
          style={{
            marginRight: '20px',
            width: '22%',
            height: '100px',
            padding: '10px',
            background: 'linear-gradient(45deg, #66bb6a, #4caf50)',
            color: '#fff',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <p style={{ margin: 0, fontSize: '24px', fontWeight: 'bold' }}>
              {analytics && analytics.total_tasks_completed ? analytics.total_tasks_completed : '0'}
            </p>
            <h3 style={{ margin: 0, fontSize: '16px', fontWeight: '500' }}>Total Allocated Task Completed</h3>
          </div>
        </Paper>
      </div>



      <Typography sx={{ marginTop: '20px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px', marginBottom: '20px' }}>Tasks by Date</Typography>
      <TaskCompletedList />
      <Typography sx={{ marginTop: '40px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px', marginBottom: '20px' }}>Tasks by Experts</Typography>

      {/* <div style={{display: "flex", gap: "45px"}}>
        <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
        <div style={{marginTop: "-10px"}}>
          <PaginationEntries itemsPerPage={itemsPerPage} onChangeRowsPerPage={handleChangeRowsPerPage} />
        </div>
      </div> */}

      <ToggleButtonGroup
        color="standard"
        value={tableSwitch}
        exclusive
        onChange={handleChangeToggle}
      >
        <ToggleButton
          value="TASK"
          style={{
            border: 'none',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            color: tableSwitch === 'TASK' ? '#03BBD2' : '#6C6C6C',
            borderBottom: tableSwitch === 'TASK' ? '3px solid #03BBD2' : 'none',
            backgroundColor: 'inherit',
            textTransform: 'none'
          }}
        >
          Tasker
        </ToggleButton>
        <ToggleButton
          value="REVIEW"
          style={{
            border: 'none',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            color: tableSwitch === 'REVIEW' ? '#03BBD2' : '#6C6C6C',
            borderBottom: tableSwitch === 'REVIEW' ? '3px solid #03BBD2' : 'none',
            backgroundColor: 'inherit',
            textTransform: 'none'
          }}
        >
          Reviewer
        </ToggleButton>
      </ToggleButtonGroup>
      <AnalyticsListTable />
    </div>
  )
}

export default AnalyticsMain
