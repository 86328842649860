import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import { deleteCookie } from '../login/cookies';
import Logo from '../login/Logo';
import DescriptionIcon from '@mui/icons-material/Description';
import PeopleIcon from '@mui/icons-material/People';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { Accordion, AccordionDetails, AccordionSlots, AccordionSummary, CircularProgress, Fade } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupIcon from '@mui/icons-material/Group';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { usersTabData } from './data';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function HomePage() {
  const [open, setOpen] = React.useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const dashboardAccess = useSelector((state: RootState) => state.company.company.data.modules.dashboard)
  const { getStatus } = useSelector((state: RootState) => state.company)
  const [expanded, setExpanded] = React.useState(false)

  const getActiveLink = (path: string) => {
    if (path.includes('dashboard')) return 'dashboard';
    if (path.includes('users') || path.includes('adduser')) {
      return 'users';
    }

    if (path.includes('experts') || path.includes('addexpert')) {
      return 'experts';
    }

    if (path.includes('projects') || path.includes('addproject') || path.includes('bulkupload') || path.includes('addconfig') || path.includes('uploadtask') || path.includes('allocation') || path.match(/project\/[^/]+/)) {
      return 'projects';
    }
    if (path.includes('vendors')) {
      return 'vendors';
    }
    return 'dashboard';
  };

  const [activeLink, setActiveLink] = React.useState<string>(getActiveLink(location.pathname));

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleItemClick = (text: string) => {
    setActiveLink(text.toLowerCase());
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    deleteCookie("token");
    navigate('/login');
  };

  React.useEffect(() => {
    setActiveLink(getActiveLink(location.pathname));
  }, [location.pathname]);

  React.useEffect(() => {
    if (window.location.pathname === '/')
      navigate('/dashboard')
  }, []);

  const handleClick = () => {
    navigate('/dashboard')
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Drawer variant="permanent" open={open}>
        <AppBar position="fixed" sx={{ backgroundColor: '#FFFFFF' }}>
          <Toolbar>
            <IconButton
              aria-label="toggle drawer"
              edge="start"
              sx={{
                marginRight: 5,
              }}
              onClick={open ? handleDrawerClose : handleDrawerOpen}
            >
              {open ? <CloseIcon sx={{ color: '#10383D' }} /> : <MenuIcon sx={{ color: '#10383D' }} />}
            </IconButton>
            <button onClick={handleClick} style={{ cursor: 'default' }}><Logo /></button>
            <Typography
              variant="body1"
              noWrap
              component="div"
              sx={{ color: '#10383D', marginLeft: 'auto', cursor: 'pointer' }}
              onClick={handleLogout}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LogoutIcon sx={{ marginRight: '10px' }} /> Logout
              </div>
            </Typography>
          </Toolbar>
        </AppBar>
        <br /><br /> <br /><br />
        <Typography
          style={{
            cursor: 'pointer',
            fontFamily: 'Montserrat',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: activeLink === 'dashboard' ? '#007BFF' : '#13282A',
            margin: '1rem',
            marginLeft: '2rem',
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleItemClick('Dashboard')}
        >
          {dashboardAccess !== null && (
            <Link to="/dashboard" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
              <GridViewRoundedIcon fontSize='small' sx={{ color: activeLink === 'dashboard' ? '#007BFF' : '#1E2C38', marginRight: '13px' }} />
              <span>Dashboard</span>
            </Link>
          )}
        </Typography>

        <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>

          <Accordion expanded={expanded}
            onChange={handleExpansion}
            slots={{ transition: Fade as AccordionSlots['transition'] }}
            slotProps={{ transition: { timeout: 400 } }}
            style={{ width: "208px", display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column", border: "none" }}
            sx={[
              {
                boxShadow: 'none',
                overflow: "hidden",
                border: 'none',
                '&:before': {
                  display: 'none',
                },
                // transition: 'max-height 0.4s ease'
              },
              expanded ? { maxHeight: '300px', } : {
                // maxHeight: '300px', 
              },
            ]}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div style={{ display: "flex", gap: "10px", position: "sticky" }}>
                <PeopleAltOutlinedIcon />
                <Typography style={{ fontWeight: "600" }}>Users</Typography>
              </div>

            </AccordionSummary>
            {expanded &&
              <AccordionDetails>
                {usersTabData.map(user => {
                  return (
                    <Typography
                      style={{
                        cursor: 'pointer',
                        fontFamily: 'Montserrat',
                        fontWeight: '600',
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: activeLink === user.value ? '#007BFF' : '#13282A',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: "center",
                        marginBottom: "10px"
                      }}
                      onClick={() => handleItemClick(user.value)}
                    >
                      <Link to={user.to} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "20px" }}>
                          <user.icon fontSize='small' sx={{ color: activeLink === user.value ? '#007BFF' : '#1E2C38', marginRight: '13px' }} />
                          <span>{user.label}</span>
                        </div>
                      </Link>
                    </Typography>
                  )
                })}
              </AccordionDetails>}
          </Accordion>
        </div>

        <Typography
          style={{
            cursor: 'pointer',
            fontFamily: 'Montserrat',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: activeLink === 'projects' ? '#007BFF' : '#13282A',
            margin: '1rem',
            marginLeft: '2rem',
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleItemClick('Projects')}
        >
          <Link to="/projects" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
            <DescriptionIcon fontSize='small' sx={{ color: activeLink === 'projects' ? '#007BFF' : '#1E2C38', marginRight: '13px' }} />
            <span>Projects</span>
          </Link>
        </Typography>





      </Drawer>
      {getStatus === STATUS.PENDING ?
        <div style={{ 'textAlign': 'center', 'height': '100vh', 'width': '100vw', 'alignContent': 'center' }} >
          <CircularProgress size={30} />
        </div>
        :
        <Outlet />
      }
    </Box>
  );
}
